import { RequiredPermissions } from '@/services/permissions'
import { IModulesConfig } from '@sennder/senn-node-microfrontend-interfaces'

export type ModuleConfig = IModulesConfig & {
  requiredPermissions?: RequiredPermissions
}

// Module without requiresPermissions is visible for all users
// Otherwise act accordingly to the permissions

export const moduleConfiguration: ModuleConfig[] = [
  {
    name: 'Contracts',
    component: 'chartering',
    devPort: '8084',
    route: '/contracts',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isUserPartOfCharteringOffice'],
    sidebarIcon: 'contracts-icon.svg',
    logContext: {
      module: 'chartering-contracts',
      codeOwners: 'asset-planning',
    },
    analyticsContext: {
      module: 'contracts',
      submodule: '',
    },
  },
  {
    name: 'Planner',
    component: 'planner-mf-component',
    devPort: '9198',
    route: '/planner',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isUserPartOfCharteringOffice'],
    sidebarIcon: 'planner-icon.svg',
    logContext: {
      module: 'planner-mf-component',
      codeOwners: 'planning',
    },
    analyticsContext: {
      module: 'planner',
      submodule: '',
    },
  },
  {
    name: 'Marketplace',
    component: 'marketplace',
    devPort: '9104',
    route: '/marketplace',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isUserPartOfCharteringOffice'],
    sidebarIcon: 'marketplace-icon.svg',
    logContext: {
      module: 'marketplace-mf-component',
      codeOwners: 'pod-discovery',
    },
    analyticsContext: {
      module: 'marketplace',
      submodule: '',
    },
  },
  {
    name: 'OrderDetails',
    component: 'marketplace',
    devPort: '9104',
    route: '/order-details/:idForStaff',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isUserPartOfCharteringOffice'],
    logContext: {
      module: 'marketplace-mf-component',
      codeOwners: 'pod-discovery',
    },
    analyticsContext: {
      module: 'order-details',
      submodule: '',
    },
  },
  {
    name: 'TenderDetails',
    component: 'marketplace',
    devPort: '9104',
    route: '/tender-details/:id',
    layout: 'AppLayoutMain',
    middlewares: ['isAuthenticated', 'isUserPartOfCharteringOffice'],
    logContext: {
      module: 'marketplace-mf-component',
      codeOwners: 'pod-discovery',
    },
    analyticsContext: {
      module: 'tender-details',
      submodule: '',
    },
  },
]
