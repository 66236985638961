import { IAnalyticsContext } from '@sennder/senn-node-microfrontend-interfaces'
import { AnalyticsProvider } from '@sennder/shell-utilities'

import { convertToKebabCase } from '@/modules/utils'
import { logger } from '@/services/logger/loggers'

export const DEFAULT_EVENT_SOURCE = 'orcas_chartering'

const getPageViewEventName = (name: string): string => {
  if (!name) {
    throw Error(
      '[chartering-shell - Analytics]: Could not track invalid page name'
    )
  }
  return `orcas/view/${convertToKebabCase(name)}-page`
}

export class AppAnalyticsProvider extends AnalyticsProvider {
  // enforce app specific analytics configuration
  constructor(context: IAnalyticsContext) {
    super({
      context,
      logger,
      messageSource: DEFAULT_EVENT_SOURCE,
      dedupePageEvents: true,
      formatEventName: undefined,
      formatPageEventName: getPageViewEventName,
    })
  }
}

export const analytics = new AppAnalyticsProvider({
  module: 'orcas-shell',
  submodule: '',
})
