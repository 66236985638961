import {
  IOrcasSharedData,
  IOrg,
  IMicrofrontendData,
  SharedDataType,
  Tenant,
  IUser,
  IUserCompany,
  OrgType,
} from '@sennder/senn-node-microfrontend-interfaces'
import {
  getCommonHeaders,
  getPermissions,
  getAuthHeader,
  getAuthToken,
  getOrgTypeAndOrdIdOrFail,
} from '@/services/authManager'
import { getFeatureFlags } from '@sennder/senn-node-feature-flags-frontend'
import { reactive, watch } from 'vue'
import { logger } from '@/services/logger/loggers'
import errorsHandler from '@/services/errors-handler'

export { loadUserData } from './userDataHelper'
import { logout } from './logoutActions'
import charteringOfficeService from '@/services/chartering-office-service'
import { getInitials } from '@/modules/utils'
import { analytics } from '@/services/analyticsProvider'
import { Optional, Prettify } from '@/types/types'
import { notify } from './notify'
import { getEmptyCompany, getEmptyContact, getEmptyUser } from '@/helpers/user'
import { sendErrorInMonitor } from '@/services/monitor'

const parseJwtFromAuthenticationHeader = (
  header: string
): Record<string, any> | null => {
  try {
    return JSON.parse(atob(header.split('.')[1]))
  } catch {
    return null
  }
}

export type OrcasExtendedData = {
  isDenylisted: boolean
  hasOrcasAccess: boolean
}

export type OrcasStoreData = Prettify<
  Optional<
    IOrcasSharedData<OrcasExtendedData>,
    'user' | 'company' | 'cpsCarrier'
  >
>

export const getEmptyData = (): OrcasStoreData => {
  return {
    type: SharedDataType.ORCAS,
    contact: getEmptyContact(),
    featureFlags: getFeatureFlags() || {},
    language: 'en',
    tenant: Tenant.SENNDER,
    hasMarketplaceAccess: false,
    isDenylisted: false,
    hasOnboardingFormAccess: false,
    hasOrcasAccess: false,
  }
}

const store = reactive({
  state: {
    data: getEmptyData(),
    callbacks: {
      getToken: getAuthHeader,
      getAuthHeader: getAuthHeader,
      getAuthToken,
      syncParentRouter: () => {},
      onUnauthorized: async () => {
        return logout()
      },
      onUnhandledError: errorsHandler,
      getCommonHeaders,
      getPermissions,
    },
    providers: {
      logger,
      notifications: {
        error: (message: string) => notify(message, 'error'),
        success: (message: string) => notify(message, 'success'),
      },
      segment: analytics,
      analytics,
      monitoring: {
        sendError: sendErrorInMonitor,
      },
    },
  } satisfies IMicrofrontendData<OrcasStoreData>,
})

// watch feature flags changes in senn-node-feature-flags-frontend and update store
watch(
  getFeatureFlags,
  (featureFlags) => {
    store.state.data.featureFlags = featureFlags
  },
  { deep: true }
)

export const getStateData = (): OrcasStoreData => store.state.data

export const getStateUser = () => {
  if (!store.state.data.user) {
    throw new Error('[Store] state.data.user is not initialized')
  }
  return store.state.data.user
}

export const getStateCompany = () => {
  if (!store.state.data.company) {
    throw new Error('[Store] state.data.company is not initialized')
  }
  return store.state.data.company
}

export const getStateProviders = () => store.state.providers
export const getStateCallbacks = () => store.state.callbacks
export const getStateFeatureFlags = () => store.state.data.featureFlags

async function getUser(): Promise<IUser> {
  const authHeader = await getAuthHeader()
  if (!authHeader) {
    throw new Error('[chartering-shell - getUser] authHeader is undefined')
  }
  const parsedToken = parseJwtFromAuthenticationHeader(authHeader)
  if (!parsedToken) {
    throw new Error('[chartering-shell - getUser] parsedToken is undefined')
  }
  const user = {
    ...getEmptyUser(),
    uuid: parsedToken['https://sennder.com/user_id'],
    email: parsedToken['https://sennder.com/email'],
  }

  if (!user.uuid) {
    throw new Error('[chartering-shell - getUser] user.uuid is undefined')
  }

  return user
}

async function getUserCompany(user: IUser, org?: IOrg): Promise<IUserCompany> {
  if (!user.uuid) {
    throw new Error(
      '[chartering-shell - getUserCompany] user.uuid is undefined'
    )
  }
  if (org?.orgType !== 'chartering-office') return getEmptyCompany()
  /*
    Since we are calling this method as part of loadUserState, it can be called outside of a router
    and we cant catch this case in router and redirect on no permission screen.
    We just identified this flow, that when the user does not exist in the chartering office, like on a screen,
    it's the right time to show no permission screen.
  */
  try {
    const charteringOffice = await charteringOfficeService.getCharteringOffice(
      user.email
    )
    const charteringUser = await charteringOfficeService.getUser(user.uuid)

    return {
      ...getEmptyCompany(),
      name: `${charteringUser.role} | ${charteringOffice.name}`,
    }
  } catch (error) {
    logger.warn(
      '[chartering-shell - getUserCompany]: Org type is not chartering office, skipping company loading',
      {}
    )
    return getEmptyCompany()
  }
}

export const loadState = async () => {
  try {
    const data = store.state.data
    data.user = await getUser()
    data.org = await getOrgOrFetch(data)
    data.company = await getUserCompany(data.user, data.org)
    return true
  } catch (error) {
    logger.error('[chartering-shell - loadState]: Error while loading state', {
      error,
    })

    await logout()

    return false
  }
}

export const getOrgOrFetch = async (data: OrcasStoreData) => {
  let dataOrg = data.org

  if (dataOrg) return dataOrg
  try {
    const { orgId, orgType } = await getOrgTypeAndOrdIdOrFail()
    if (orgType && orgId) {
      dataOrg = { orgID: orgId, orgType: orgType as OrgType }
    }
  } catch (error) {
    logger.error(
      '[chartering-shell - getOrgOrFetch]: Error while getting org',
      { error }
    )
  }

  return dataOrg
}

export const setStoreLanguage = async (language: string) => {
  store.state.data.language = language
}

export const setStoreFeatureFlags = (
  featureFlags: Record<string, boolean | string>
) => {
  store.state.data.featureFlags = featureFlags
}

export const setStoreData = (data: OrcasStoreData) => {
  store.state.data = data
}

export const clearState = () => {
  store.state.data = getEmptyData()
}

export const getCompanyName = (): string =>
  store.state.data.company?.name || 'Unknown role/chartering office'

export const getUserName = (): string =>
  store.state.data.user?.fullNameWithoutTitle || 'Unknown User'

export const getUserNameInitials = (): string => {
  return getInitials(getUserName())
}
