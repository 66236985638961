import { RouteLocationNormalized, Router } from 'vue-router'
import { getStateData } from '@/store'
import { getFeatureFlagForRoute } from '@/modules/visibility-handlers'
import { moduleConfiguration } from '@/modules-configuration'
import { LOGIN_ROUTE, NO_PERMISSION_ROUTE } from '@/common/constants'
import {
  isModuleHasRequiredPermission,
  isUserHasAnyPermission,
} from '@/services/permissions'

const checkRouteFeatureFlag = (route: RouteLocationNormalized) => {
  const routeFeatureFlag = getFeatureFlagForRoute(route)
  if (!routeFeatureFlag) {
    return true
  }
  return !!getStateData().featureFlags[routeFeatureFlag]
}

export const routeNavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
) => {
  const middlewares = to.meta.middlewares

  if (!middlewares || middlewares.length === 0) {
    return true
  }

  for (const middleware of middlewares) {
    const result = await middleware({ to, from })
    if (
      typeof result === 'object' ||
      typeof result === 'string' ||
      result === false
    ) {
      return result
    }
  }

  return checkRouteFeatureFlag(to)
}

export const registerMiddlewares = (router: Router) => {
  router.beforeEach(routeNavigationGuard)
  router.beforeEach((to, from, next) => {
    // Allow navigation if the route is either login or no permission
    if ([LOGIN_ROUTE, NO_PERMISSION_ROUTE].includes(to.path)) {
      return next()
    }
    //Order of the middlewares is important
    if (!isUserHasAnyPermission()) {
      return next({ path: NO_PERMISSION_ROUTE })
    }

    // Find module configuration based on module name
    const moduleConfig = moduleConfiguration.find(
      (module) => module.name === to.name
    )
    // Navigate to no permission route if no module configuration not have the required permissions for the module
    if (moduleConfig && !isModuleHasRequiredPermission(moduleConfig)) {
      return next({ path: LOGIN_ROUTE })
    }

    // Proceed with navigation if the module exists and the user has required permissions
    next()
  })
}
