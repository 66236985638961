import {
  IAction,
  IActionPermission,
  ICommonHeaders,
} from '@sennder/senn-node-microfrontend-interfaces'

/*

Implement this once Auth mF is updated
import { ENV } from '@/common/config'
import { FederatedModule } from '@sennder/federated-module-loader'

const authMf = new FederatedModule({
  moduleFederationPluginName: 'internalauthmfcomponent',
  environment: 'hot', // ENV
  devPort: 9110,
  npmName: 'internal-auth-mf-component',
})

 let remoteIsUserLoggedin: (() => Promise<boolean>) | undefined

export const isLoggedIn = async () => {
  if (remoteIsUserLoggedin) {
    return remoteIsUserLoggedin()
  }

  const { isUserLoggedIn } = await authMf.getModule<{
    isUserLoggedIn: () => Promise<boolean>
  }>('./federatedFunctions')

  remoteIsUserLoggedin = isUserLoggedIn
  return isUserLoggedIn()
} */

export interface IAuth {
  getCommonHeaders: () => Promise<ICommonHeaders>
  logoutFromShell: () => Promise<void>
  getPermissions: (actions: IAction[]) => Promise<IActionPermission[]>
  getOrgTypeAndOrdIdOrFail: () => Promise<
    Record<'orgType' | 'orgId', string | null>
  >
  getFullAuthTokenWithType: () => Promise<string | null>
}

const auth: IAuth = {
  getCommonHeaders: () => new Promise((resolve) => resolve({} as any)),
  logoutFromShell: () => new Promise((resolve) => resolve()),
  getPermissions: () => new Promise((resolve) => resolve([])),
  getOrgTypeAndOrdIdOrFail: () => new Promise((resolve) => resolve({} as any)),
  getFullAuthTokenWithType: () => new Promise((resolve) => resolve(null)),
}

export function setGetCommonHeaders(
  getCommonHeaders: () => Promise<ICommonHeaders>
) {
  auth.getCommonHeaders = getCommonHeaders
}

export function setLogoutFromShell(logoutFromShell: () => Promise<void>) {
  auth.logoutFromShell = logoutFromShell
}

export function setGetPermissions(
  getPermissions: (actions: IAction[]) => Promise<IActionPermission[]>
) {
  auth.getPermissions = getPermissions
}

export function setGetOrgTypeAndOrdIdOrFail(
  getOrgTypeAndOrdIdOrFail: () => Promise<
    Record<'orgType' | 'orgId', string | null>
  >
) {
  auth.getOrgTypeAndOrdIdOrFail = getOrgTypeAndOrdIdOrFail
}

export function setGetFullAuthTokenWithType(
  getAuthToken: () => Promise<string | null>
) {
  auth.getFullAuthTokenWithType = getAuthToken
}

export async function getCommonHeaders() {
  return await auth.getCommonHeaders()
}

export async function getOrgTypeAndOrdIdOrFail() {
  return await auth.getOrgTypeAndOrdIdOrFail()
}

export async function getAuthHeader(): Promise<string | null> {
  return await auth.getFullAuthTokenWithType()
}

export async function getAuthToken() {
  const token = await auth.getFullAuthTokenWithType()
  return token?.split(' ')[1] ?? null
}

export async function authLogout() {
  await auth.logoutFromShell()
}

export async function getPermissions(actions: IAction[]) {
  return await auth.getPermissions(actions)
}
