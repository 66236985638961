import { logger } from '@/services/logger/loggers'
import { clearState } from '@/store/index'
import router from '@/router'
import { authLogout } from '@/services/authManager'
import { anonymizeTracking } from '@/services/tracking'
import { anonymizeUserInLaunchDarkly } from '@/services/launchDarkly'
import { LOGIN_ROUTE } from '@/common/constants'

export type BaseLogoutOptions = {
  callBackend?: boolean
  calledByUser?: boolean
  redirectTo?: string
}

export const logout = async (
  options: BaseLogoutOptions = { callBackend: false, calledByUser: false }
) => {
  anonymizeTracking()

  await authLogout()

  localStorage.removeItem('authenticationStatus')
  logger.info('[chartering-shell] Logout successful', options)
  logger.unsetUserData()
  let nextUrl = LOGIN_ROUTE
  if (options.redirectTo) {
    nextUrl += `?redirectTo=${encodeURIComponent(options.redirectTo)}`
  }
  await router.push(nextUrl)
  clearState()

  await anonymizeUserInLaunchDarkly()
}
