import { IAction, OrgType } from '@sennder/senn-node-microfrontend-interfaces'
import { RequiredPermissionsOrganisations } from '@/services/permissions/permissions'
import { getPermissions } from '@/services/authManager'

type Action = {
  action: string
  entityID: string
  entityType: RequiredPermissionsOrganisations
}

const createActionsForOrg = (
  orgType: OrgType,
  orgID: string,
  permissionsForOrg: string[]
): IAction[] => {
  return permissionsForOrg.map((permission) => {
    const action: Action = {
      action: permission,
      entityID: String(orgID),
      entityType: mapOrgTypeToActionType(
        orgType
      ) as RequiredPermissionsOrganisations,
    }
    return action
  })
}

const mapOrgTypeToActionType = (orgType: OrgType): string => {
  switch (orgType) {
    case 'carrier':
      return 'carrier'
    case 'chartering-office':
      return 'chartering_office'
    case 'organization':
      return 'organization'
    default:
      throw new Error(`Unknown OrgType: ${orgType}`)
  }
}

export const checkPermissionForOrganisation = async (
  orgType: OrgType,
  orgID: string,
  permissionsForOrgFromModule: string[]
) => {
  const actionsForOrg = createActionsForOrg(
    orgType,
    orgID,
    permissionsForOrgFromModule
  )
  return await getPermissions(actionsForOrg)
}
