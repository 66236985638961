import { CHARTERING_OFFICES_URL } from '@/common/config'
import { getAuthHeader } from './authManager'
import { HttpClient } from './http-client/HttpClient'
import httpProvider from './http-provider'

export interface ICharteringOffice {
  uuid: string
  name?: string
  legacy_id?: number
  legacy_contact_id?: number
}

export interface ICharteringOfficeUser {
  uuid: string
  name: string
  legacy_id: string
  phone: string
  email: string
  role: string
}

class CharteringOfficeService extends HttpClient {
  public async getCharteringOffice(email: string): Promise<ICharteringOffice> {
    return this.get(`/orcas-chartering/api/v1/user/${email}/chartering-office`)
  }

  public async getUser(uuid: string): Promise<ICharteringOfficeUser> {
    return this.get(`/orcas-chartering/api/v1/user/${uuid}`)
  }
}

export default new CharteringOfficeService({
  httpProvider,
  getAuthHeader: getAuthHeader,
  baseURL: CHARTERING_OFFICES_URL,
})
