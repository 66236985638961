import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { loadUserData } from '@/store'
import '@/assets/tailwind.global.css'
import '@/assets/scss/reset.scss'
import '@sennder/design-system-core'
import '@/assets/scss/styles.scss'

import { initializeVue as initLaunchDarkly } from '@sennder/senn-node-feature-flags-frontend/lib/integrations/vue'
import { LAUNCH_DARKLY_KEY } from '@/common/config'
import registerPlugins from '@/plugins'
import { registerRoutesAndMiddlewares } from './router/routes'
import { watchMenuChanges } from '@/layouts/menuHelper'
import { startTracking } from './services/tracking'
import { logger } from './services/logger/loggers'
import { getLaunchDarklyAnonymousContext } from './services/launchDarkly'
import { translationProvider } from '@/services/translationProvider'
import '@sennder/shell-utilities/dist/style.css'
async function initialize() {
  if (!LAUNCH_DARKLY_KEY) {
    throw new Error('LAUNCH_DARKLY_KEY not found')
  }
  await initLaunchDarkly(
    LAUNCH_DARKLY_KEY,
    await getLaunchDarklyAnonymousContext()
  )

  await loadUserData(true)
  await translationProvider.initialize()
  watchMenuChanges()
}

async function start() {
  // TODO: if required, check for user consent before starting tracking
  startTracking()

  try {
    await initialize()
  } catch (error) {
    logger.error(`[chartering] shell init`, { error })
  } finally {
    const app = createApp(App)
    registerRoutesAndMiddlewares(router)
    app.use(router)
    registerPlugins(app)
    app.mount('#app')
  }
}

start()
