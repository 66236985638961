import {
  IUser,
  IUserCompany,
  IUserContact,
} from '@sennder/senn-node-microfrontend-interfaces'

export function getEmptyCompany(): IUserCompany {
  return {
    accountOwnerEmail: '',
    address: '',
    carrierId: '',
    carrierType: '',
    companyCarrierTier: 0,
    companyIsInVettingProcess: false,
    contact: '',
    germanVatId: '',
    isCarrier: false,
    name: '',
    vatId: '',
  }
}

export function getEmptyContact(): IUserContact {
  return {
    carrierId: '',
    email: '',
    firstName: '',
    fullName: '',
    jobTitle: '',
    lastName: '',
    phone: '',
  }
}

export function getEmptyUser(): IUser {
  return {
    cookies: [],
    featureFlags: [],
    firstLogin: false,
    firstName: '',
    fullNameWithoutTitle: '',
    groupNames: [],
    id: '',
    inCustomerGroup: false,
    inFinanceGroup: false,
    inStaffGroup: false,
    language: '',
    lastName: '',
    paymentTermPopUpDaysPast: 0,
    permissions: [],
    positionTitle: '',
    timezone: '',
    uiLanguage: '',
    username: '',
    email: '',
    uuid: '',
  }
}
