export const createRandomString = () =>
  Math.random()
    .toString(36)
    .substring(4)
    .replace(/[^a-zA-Z]+/g, '')

export const convertToKebabCase = (value: string): string => {
  return value
    .replace(/[A-Z]/g, function (match) {
      return '-' + match.toLowerCase()
    })
    .replace(/^-/, '')
}

export const getInitials = (name: string): string => {
  let initials = name.split(/[ _]/)

  if (initials.length > 3) {
    initials = [initials[0], initials[initials.length - 1]]
  }
  return initials.map((n) => n.slice(0, 1)).join('')
}
