import { watch } from 'vue'

import {
  INotificationCenterData,
  INotificationCenterMicrofrontendData,
  MountNotifications,
  SharedDataType,
} from '@sennder/senn-node-microfrontend-interfaces'
import { FederatedModule } from '@sennder/federated-module-loader'
import {
  cloneReactiveToRaw,
  MicroFrontendLogger,
} from '@sennder/shell-utilities'

import { ENV } from '@/common/config'
import errorsHandler from '@/services/errors-handler'
import { getStateCallbacks } from '@/store'
import { logger } from '@/services/logger/loggers'
import { AppAnalyticsProvider } from '@/services/analyticsProvider'
import { getStateData } from '@/store'
import router from '@/router'
import { loggerInstance } from './logger'

export const notificationsModule = new FederatedModule<INotificationCenterData>(
  {
    devPort: 9199,
    environment: ENV || 'prod',
    moduleFederationPluginName: 'notificationcentermf',
    npmName: 'notification-center-mf',
    logger,
  }
)

let mountFunction: MountNotifications
let unmount: (() => void) | null = null
let unwatch: (() => void) | null = null

function getNotificationCenterData(): INotificationCenterData {
  const { language, featureFlags } = getStateData()
  return {
    shell: SharedDataType.CHARTERING,
    language,
    featureFlags,
  }
}

async function loadNotificationsCenter(elementId: string) {
  if (!mountFunction) {
    mountFunction = (
      await notificationsModule.getModule<{ mount: MountNotifications }>(
        './notificationcentermfApp'
      )
    )?.mount
  }

  const microfrontendData: INotificationCenterMicrofrontendData = {
    data: getNotificationCenterData(),
    callbacks: {
      getAuthHeader: getStateCallbacks().getAuthHeader,
      getAuthToken: getStateCallbacks().getAuthToken,
      getCommonHeaders: getStateCallbacks().getCommonHeaders,
      navigate: (route) => {
        router.push(route)
      },
    },
    providers: {
      logger: new MicroFrontendLogger(
        {
          module: 'notifications',
          codeOwners: 'frontend-systems',
        },
        () => loggerInstance
      ),
      analytics: new AppAnalyticsProvider({
        module: 'notifications',
        submodule: '',
      }),
    },
  }
  const mountResult = mountFunction(`#${elementId}`, microfrontendData)

  unwatch = watch(
    () => getStateData(),
    () => {
      mountResult.onSharedDataChanged(
        cloneReactiveToRaw(getNotificationCenterData())
      )
    },
    { deep: true }
  )

  unmount = mountResult.unmount
}

export async function initNotifications(elementId: string) {
  try {
    await loadNotificationsCenter(elementId)
  } catch (error: any) {
    errorsHandler(error)
  }
}

export function stopNotifications() {
  unwatch?.()
  unmount?.()
}
