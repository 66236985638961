import { ref, watch } from 'vue'
import { ModuleConfig, moduleConfiguration } from '../modules-configuration'
import { getStateData } from '@/store'
import { analytics } from '@/services/analyticsProvider'
import {
  isModuleHasRequiredPermission,
  modulesPermissions,
} from '@/services/permissions'
import { logger } from '@/services/logger/loggers'

export interface ISidebarLink {
  icon: string
  name: string
  route: string
  analyticsEvent?: string
  sidebarIcon?: string
}

const isModuleVisibleInMenu = async (module: ModuleConfig) => {
  if (!module.sidebarIcon) {
    return false
  }

  const isEnabledByFeatureFlag =
    !module.featureFlag || getStateData().featureFlags[module.featureFlag]
  if (isEnabledByFeatureFlag) {
    // Check persmissions from auth
    return isModuleHasRequiredPermission(module)
  }

  return false
}

const getMenuIcon = (sidebarIcon?: string): string => {
  try {
    return require(`@/assets/img/${sidebarIcon}`)
  } catch (error) {
    return require('@/assets/img/orcas-logo.svg')
  }
}

const getAnalyticsEventName = (moduleName: string) => {
  if (!moduleName || moduleName === '') {
    return undefined
  }
  return `orcas/click/${moduleName.toLowerCase()}/open`
}

export const menuItems = ref<ISidebarLink[]>([])

export const initMenuItems = async () => {
  if (!getStateData().org?.orgType) {
    return
  }

  const visibleModules: ModuleConfig[] = []

  for (const module of moduleConfiguration) {
    try {
      const shouldBeVisible = await isModuleVisibleInMenu(module)

      if (shouldBeVisible) {
        visibleModules.push(module)
      }
    } catch (e) {
      logger.error(
        `[chartering-shell - menuHelper]: Failed to fetch module permissions for ${module.name}`,
        { error: e }
      )
    }
  }

  menuItems.value = visibleModules.map(
    (module): ISidebarLink => ({
      icon: getMenuIcon(module.sidebarIcon),
      name: module.name,
      route: module.route,
      analyticsEvent: getAnalyticsEventName(module.name),
      sidebarIcon: module.sidebarIcon,
    })
  )
}

export const onTrackMenuItem = (analyticsEvent: string) => {
  analytics.trackEvent(analyticsEvent)
  window.scrollTo(0, 0)
}

/* 
  Watch for Org or FF changes to re-initialize menu items

  Called immediately in order to generate menuItems on first load
  Deep to listen to all changes within objects
*/

export const watchMenuChanges = () => {
  const data = getStateData()
  watch(
    [() => data.org, () => data.featureFlags, () => modulesPermissions],
    () => initMenuItems(),
    {
      deep: true,
    }
  )

  return initMenuItems()
}
