import { onDispatchToastNotification } from '@sennder/senn-node-microfrontend-event-bus'

import { isLocalEnv } from '@/common/config'
import { logger } from '@/services/logger/loggers'
import { notify } from '@/store/notify'
import { sendErrorInMonitor } from '@/services/monitor'

export type INotification = {
  message: string
  category: onDispatchToastNotification['category']
}

type IError = {
  notification: INotification
}

export function executeSafelyWithLogging<T extends (...args: any[]) => any>(
  callback: T,
  callbackName: string
) {
  try {
    callback()
  } catch (error) {
    logger.error(
      `[{{ data.component }}] Error while executing ${callbackName}: ${error}`,
      { error }
    )
  }
}

export default (error: string | IError | TypeError, message = '') => {
  const notificationMessage = (error as IError).notification?.message || null

  if (notificationMessage) {
    const notificationCategory =
      (error as IError).notification?.category || 'error'

    notify(notificationMessage, notificationCategory)
  }

  if (isLocalEnv()) {
    notify(
      `Check the console log for details. This message won't be displayed in production. ${message}`,
      'error'
    )
    console.error(error, message)
  } else {
    sendErrorInMonitor(error as Error, {
      message,
    })
  }
}
