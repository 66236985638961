import {
  identifyUserInAnalytics,
  initAnalytics,
  resetAnalyticsSession,
  stopAnalytics,
} from '@/services/analytics'
import { identifyUserInLogger, initLogger, stopLogger } from '@/services/logger'
import { logger } from '@/services/logger/loggers'
import {
  identifyUserInMonitor,
  initMonitor,
  stopMonitor,
} from '@/services/monitor'
import { initSentry, stopSentry } from '@/services/sentry'
import { getStateUser } from '@/store'

let state: 'stopped' | 'started' = 'stopped'

export function startTracking() {
  if (state === 'started') {
    return
  }
  initLogger()
  initMonitor()
  initAnalytics()
  initSentry()
  state = 'started'

  log('tracking has started')
}

export function identifyUserInTracking(onLogin = false) {
  if (state === 'stopped') {
    return
  }
  const user = getStateUser()

  identifyUserInAnalytics({ onLogin })
  identifyUserInLogger(String(user.uuid), user.email || '')
  identifyUserInMonitor(String(user.uuid), user.email || '')

  log('user is identified')
}

export function anonymizeTracking() {
  if (state === 'stopped') {
    return
  }
  resetAnalyticsSession()

  log('user is anonymized')
}

export function stopTracking() {
  stopAnalytics()
  stopLogger()
  stopMonitor()
  stopSentry()
  state = 'stopped'

  log('tracking has stopped')
}

function log(message: string) {
  const prefix = '[tms - tracking]'
  logger.info(`${prefix} ${message}`, {})
  console.log(`${prefix} ${message}`)
}
