import { isNotAuthenticated } from '@/router/middlewares/middlewares'
import { LOGIN_ROUTE, NO_PERMISSION_ROUTE } from '@/common/constants'

export default [
  {
    path: LOGIN_ROUTE,
    name: 'Login',
    meta: {
      middlewares: [isNotAuthenticated],
      layout: 'AppLayoutAuth',
      analyticsContext: { module: 'auth', submodule: '' },
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
  {
    path: NO_PERMISSION_ROUTE,
    name: 'NoPermission',
    meta: {
      analyticsContext: { module: 'auth', submodule: '' },
    },
    component: () => import('./AuthMicrofrontend.vue'),
  },
]
