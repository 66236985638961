import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

import { getAuthToken } from '@/services/authManager'
import { getOrgOrFetch, getStateData } from '@/store'
import { menuItems } from '@/layouts/menuHelper'
import { logger } from '@/services/logger/loggers'
import { LOGIN_ROUTE, NO_PERMISSION_ROUTE } from '@/common/constants'

export type MiddlewareOptions = {
  to: RouteLocationNormalized
  from: RouteLocationNormalized
}

export type Middleware = (
  options: MiddlewareOptions
) =>
  | RouteLocationRaw
  | boolean
  | string
  | Promise<RouteLocationRaw | boolean | string>

export const isCompanyCarrier: Middleware = async (_) => {
  if (getStateData().company?.isCarrier) {
    return true
  } else {
    // TODO: Disabled for now to avoid logout from another µFs
    // const { logout } = await import('@/store/logoutActions')
    // await logout({ callBackend: true })
    return false
  }
}

// Checks that user has access to requested module.
// Currently, source of truth is menu configuration.
export const hasPermission: Middleware = async ({ to }) => {
  if (menuItems.value.some((item) => item.route === to.path)) {
    return true
  } else {
    const redirectTo = menuItems.value[0].route
    logger.info('[chartering shell] Trying to access not allowed route', {
      requestedPath: to.path,
      redirectedTo: redirectTo,
    })
    return { path: redirectTo }
  }
}

export const isAuthenticated: Middleware = async ({ to }) => {
  const isAuthenticated = !!(await getAuthToken())
  if (isAuthenticated) {
    return true
  } else {
    return {
      path: LOGIN_ROUTE,
      query: { redirectTo: encodeURIComponent(to.fullPath) },
    }
  }
}

export const isRootAuthenticated: Middleware = async () => {
  const isAuthenticated = !!(await getAuthToken())
  if (isAuthenticated) {
    return { path: menuItems.value[0].route }
  } else {
    return { name: 'Login' }
  }
}

export const isNotAuthenticated: Middleware = async () => {
  const isAuthenticated = !!(await getAuthToken())
  if (isAuthenticated) {
    return { path: menuItems.value[0].route }
  } else {
    return true
  }
}

export const isUserPartOfCharteringOffice: Middleware = async (_) => {
  const org = await getOrgOrFetch(getStateData())
  if (org) {
    return true
  } else {
    return { path: NO_PERMISSION_ROUTE }
  }
}

export const projectMiddlewares = new Map<string, Middleware>([
  ['isCompanyCarrier', isCompanyCarrier],
  ['hasPermission', hasPermission],
  ['isAuthenticated', isAuthenticated],
  ['isRootAuthenticated', isRootAuthenticated],
  ['isNotAuthenticated', isNotAuthenticated],
  ['isUserPartOfCharteringOffice', isUserPartOfCharteringOffice],
])
