import { Ref, ref } from 'vue'
import { ModuleConfig } from '@/modules-configuration'
import {
  IActionPermission,
  IOrg,
  OrgType,
} from '@sennder/senn-node-microfrontend-interfaces'
import { checkPermissionForOrganisation } from '@/services/permissions/checkPermissionForOrganisation'

export const modulesPermissions: Ref<Record<OrgType, IActionPermission[]>> =
  ref({} as Ref<Record<OrgType, IActionPermission[]>>)

export const initPermissions = async (
  moduleConfiguration: ModuleConfig[],
  orgId?: IOrg['orgID']
) => {
  if (!orgId) return
  modulesPermissions.value = await fetchModulesPermissions(
    orgId,
    moduleConfiguration
  )
}

const fetchModulesPermissions = async (
  orgId: IOrg['orgID'],
  moduleConfiguration: ModuleConfig[]
): Promise<Record<OrgType, IActionPermission[]>> => {
  const permissionsForOrg = {} as Record<OrgType, IActionPermission[]>
  const accumulatedPermissions = {} as Record<OrgType, Set<string>>

  // Accumulate permissions for each organization type across all modules
  for (const module of moduleConfiguration) {
    const modulePermissions = module.requiredPermissions
    if (!modulePermissions) continue

    for (const [orgType, permissions] of Object.entries(modulePermissions)) {
      if (!accumulatedPermissions[orgType as OrgType]) {
        accumulatedPermissions[orgType as OrgType] = new Set()
      }
      permissions.forEach((permission) =>
        accumulatedPermissions[orgType as OrgType].add(permission)
      )
    }
  }

  // Prepare promises for fetching user permissions for each organization type
  const permissionPromises = Object.entries(accumulatedPermissions).map(
    async ([orgType, permissions]) => {
      const actions = Array.from(permissions)
      permissionsForOrg[orgType as OrgType] =
        await checkPermissionForOrganisation(
          orgType as IOrg['orgType'],
          orgId,
          actions
        )
    }
  )

  await Promise.all(permissionPromises)

  return permissionsForOrg
}

export const isModuleHasRequiredPermission = (
  moduleConfig: ModuleConfig
): boolean => {
  const { requiredPermissions } = moduleConfig
  // in case there are no permissions at all return true.
  // This is the case when the we didnt define any permissions in the module configuration
  if (!requiredPermissions) return true

  // Check if there's at least one orgType where some of the required permissions are met
  return Object.entries(requiredPermissions).some(
    ([orgType, requiredPerms]) => {
      const userPerms = modulesPermissions.value[orgType as OrgType] || []
      // Check if the user has some of the required permissions for this orgType
      return requiredPerms.some((reqPerm) =>
        userPerms.some(
          (userPerm) => userPerm.action === reqPerm && userPerm.isAllowed
        )
      )
    }
  )
}

export const isUserHasAnyPermission = (): boolean => {
  const orgPermissions = Object.values(modulesPermissions.value)
  // in case there are no permissions at all, return true.
  // This is the case when the we didnt define any permissions in the module configuration
  if (orgPermissions.length === 0) return true
  for (const permissions of Object.values(modulesPermissions.value)) {
    // Check if there's any permission that is allowed
    // So we can allow the user to access the microfrontend
    if (permissions.some((permission) => permission.isAllowed)) {
      return true
    }
  }
  return false
}
