import { loadModule } from '../loader'
import { ENV } from '@/common/config'

const NAMESPACE = 'internalauthmfcomponent'
const REMOTE = './internalauthmfcomponentApp'

// For local development set dev to 'http://localhost:9110/'
const urls = {
  dev: 'https://cdn.dev.cloud.sennder.com/npm/internal-auth-mf-component/',
  uat: 'https://cdn.uat.cloud.sennder.com/npm/internal-auth-mf-component/',
  prod: 'https://cdn.cloud.sennder.com/npm/internal-auth-mf-component/',
}

const environment = ENV || 'stg'

export const auth = {
  bootstrap: async () => {
    if (auth.mount) return auth.mount
    const { mount } = await loadModule(
      `${urls[environment as keyof typeof urls]}remoteEntry.js`,
      NAMESPACE,
      REMOTE
    )
    auth.mount = mount
    return mount
  },
  mount: null,
}
