import { ComputedRef, computed, reactive, ref } from 'vue'
import { IconCategory, Notification } from './types'

const DEFAULT_ICON = 'star.svg'
const DEFAULT_ICON_CATEGORY = 'info'
const NOTIFICATION_LIFETIME = 7000
export const ONE_SECOND = 1000
export const ONE_MINUTE = ONE_SECOND * 60
export const ONE_HOUR = ONE_MINUTE * 60
export const ONE_DAY = ONE_HOUR * 24
export const ONE_MONTH = ONE_DAY * 30.5
export const ONE_YEAR = ONE_MONTH * 12

const categories: Record<IconCategory, { [key: string]: string }> = {
  error: { icon: 'alert-circle.svg', type: 'error' },
  info: { icon: 'star.svg', type: 'info' },
  internal: { icon: 'alert-circle.svg', type: 'error' },
  success: { icon: 'check.svg', type: 'success' },
  warning: { icon: 'star.svg', type: 'warning' },
} as const

const idCounter = ref(0)
const notifications: Notification[] = reactive([])

const getIcon = (category: IconCategory) => {
  return categories[category] ? categories[category].icon : DEFAULT_ICON
}

const getType = (category: IconCategory) => {
  return categories[category]
    ? categories[category].type
    : DEFAULT_ICON_CATEGORY
}

const sideNotificationsTypes = Object.keys(categories)

export const getNotifications = computed(() => {
  return notifications
})

export const getSideNotifications: ComputedRef<Notification[]> = computed(
  () => {
    return notifications.filter((notification) =>
      sideNotificationsTypes.includes(notification.type)
    ) as Notification[]
  }
)

export const addSideNotification = (
  message: string,
  category: IconCategory,
  options?: NotificationOptions
) => {
  const newNotification: Notification = {
    id: ++idCounter.value,
    icon: getIcon(category),
    type: getType(category),
    ...options,
    message,
    timestamp: new Date(),
  }
  notifications.unshift(newNotification)
  setTimeout(
    () => removeNotification(newNotification.id),
    NOTIFICATION_LIFETIME
  )
}

export const removeNotification = (notificationId: number | string) => {
  const index = notifications.findIndex(
    (notification) => notification.id === notificationId
  )
  if (~index) {
    notifications.splice(index, 1)
  }
}

export const interactOnNotification = (notification: Notification) => {
  if (!notification.onClickAction) {
    return
  }
  notification.onClickAction()
  removeNotification(notification.id)
}

export const timeSince = (date: Date) => {
  const seconds = Math.round(new Date().getTime() - date.getTime())
  let interval = seconds / ONE_YEAR

  if (interval >= 1) {
    return Math.round(interval) + ' years ago'
  }
  interval = seconds / ONE_MONTH
  if (interval >= 1) {
    return Math.round(interval) + ' months ago'
  }
  interval = seconds / ONE_DAY
  if (interval >= 1) {
    return Math.round(interval) + ' days ago'
  }
  interval = seconds / ONE_HOUR
  if (interval >= 1) {
    return Math.round(interval) + ' hours ago'
  }
  interval = seconds / ONE_MINUTE
  if (interval >= 1) {
    return Math.round(interval) + ' minutes ago'
  }
  return 'few seconds ago'
}
