import { moduleConfiguration } from '@/modules-configuration'
import { IModulesConfig } from '@sennder/senn-node-microfrontend-interfaces'
import { RouteLocationNormalized } from 'vue-router'

export const getFeatureFlagForRoute = (route: RouteLocationNormalized) => {
  const module = moduleConfiguration.find(
    (module: IModulesConfig) => module.name === route.name
  )
  return module?.featureFlag
}
